import { CommonColDefFieldNamesEnum } from "../../../../../enums/AgGridColDefFieldNameEnum";
import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const OWNER_NAME_COLUMN_CONFIG: (owners: FP.Entities.IUser[]) => ISdColDef<any, any> = owners => {
  return {
    cellEditorParams: {
      field: CommonColDefFieldNamesEnum.Owner,
      getValueLabel: (ee: any) => {
        if (!owners) return "";

        const user = owners.find(e => e.id === ee.id);

        if (!user) return ee;
        return user.firstName + " " + user.lastName;
      },
      options: !!owners ? owners : [],
      others: owners,
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const OWNER_NAME_FILTER_CONFIG: ISdFilterDef = {};
